var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1200px", "no-click-animation": "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Trip Estimator")]),
          _c(
            "v-card-text",
            { staticClass: "pt-5" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { outlined: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-bold",
                                                },
                                                [_vm._v("Trip #")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-normal",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.tripRequest.id
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-bold",
                                                },
                                                [_vm._v("# Vehicles Needed")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-normal",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.tripRequest
                                                          .numVehicles
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-bold",
                                                },
                                                [_vm._v("Leave Date/Time")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-normal",
                                                },
                                                [_vm._v(_vm._s(_vm.leaveText))]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-bold",
                                                },
                                                [_vm._v("Return Date/Time")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-normal",
                                                },
                                                [_vm._v(_vm._s(_vm.returnText))]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    "Approx Round-Trip Mileage"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-normal",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.tripRequest.distance
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-bold",
                                                },
                                                [_vm._v("Destination")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-subtitle-2 font-weight-normal",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.tripRequest
                                                          .destinationId &&
                                                          _vm.destinationsById[
                                                            _vm.tripRequest
                                                              .destinationId
                                                          ]
                                                          ? _vm
                                                              .destinationsById[
                                                              _vm.tripRequest
                                                                .destinationId
                                                            ].name
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "12" } },
                    [
                      _c("v-simple-table", {
                        attrs: { dense: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c(
                                    "tr",
                                    [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-left",
                                          attrs: { width: "230" },
                                        },
                                        [_vm._v("Total Miles")]
                                      ),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Cost Per Mile"),
                                      ]),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Additional Cost Per Mile"),
                                      ]),
                                      _c("v-spacer"),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-left font-weight-bold",
                                          attrs: { width: "200" },
                                        },
                                        [_vm._v("Mileage Cost")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("tbody", [
                                  _c(
                                    "tr",
                                    [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.tripRequest.distance)
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v("$" + _vm._s(_vm.costPerMile)),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          "$" +
                                            _vm._s(
                                              _vm.tripTypesById[
                                                _vm.tripRequest.tripTypeId
                                              ].additionalCost
                                            )
                                        ),
                                      ]),
                                      _c("v-spacer"),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "text-left font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.toMoney(_vm.mileageCost))
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("v-simple-table", {
                        attrs: { dense: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c(
                                    "tr",
                                    [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-left",
                                          attrs: { width: "230" },
                                        },
                                        [_vm._v("Total Miles")]
                                      ),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Fuel Cost per Gallon"),
                                      ]),
                                      _c("v-spacer"),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-left font-weight-bold",
                                          attrs: { width: "200" },
                                        },
                                        [_vm._v("Fuel Cost")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("tbody", [
                                  _c(
                                    "tr",
                                    [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.tripRequest.distance)
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.toMoney(
                                              _vm.currentFuel.pricePerGallon
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("v-spacer"),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "text-left font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.toMoney(_vm.fuelCost))
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("v-simple-table", {
                        attrs: { dense: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c(
                                    "tr",
                                    [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-left",
                                          attrs: { width: "230" },
                                        },
                                        [_vm._v("# of Students")]
                                      ),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("Venue Cost per Student"),
                                      ]),
                                      _c("v-spacer"),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-left font-weight-bold",
                                          attrs: { width: "200" },
                                        },
                                        [_vm._v("Venue Cost")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("tbody", [
                                  _c(
                                    "tr",
                                    [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(_vm.tripRequest.totalStudents)
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "number",
                                              min: 0,
                                              dense: "",
                                              "hide-details": "",
                                              "prepend-inner-icon":
                                                "mdi-currency-usd",
                                            },
                                            on: {
                                              change: (e) =>
                                                _vm.handleCostChange(
                                                  e,
                                                  "venueCostPerStudent"
                                                ),
                                            },
                                            model: {
                                              value:
                                                _vm.tripRequest
                                                  .estVenueCostPerStudent,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tripRequest,
                                                  "estVenueCostPerStudent",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tripRequest.estVenueCostPerStudent",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("v-spacer"),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "text-left font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.toMoney(_vm.venueCost))
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("v-simple-table", {
                        attrs: { dense: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c(
                                    "tr",
                                    [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-left",
                                          attrs: { width: "230" },
                                        },
                                        [_vm._v("Driver Hours per Driver")]
                                      ),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v("District-Wide Driver Rate"),
                                      ]),
                                      _c("v-spacer"),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-left font-weight-bold",
                                          attrs: { width: "200" },
                                        },
                                        [_vm._v("Driver Costs")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("tbody", [
                                  _c(
                                    "tr",
                                    [
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "number",
                                              min: 0,
                                              dense: "",
                                              "hide-details": "",
                                            },
                                            model: {
                                              value:
                                                _vm.tripRequest.estDriverHours,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tripRequest,
                                                  "estDriverHours",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tripRequest.estDriverHours",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _vm.hasOverRideRate
                                            ? _c("v-select", {
                                                attrs: {
                                                  label: `$${
                                                    _vm.tripEventsById[
                                                      _vm.tripRequest
                                                        .tripEventIds[0]
                                                    ].driverRate
                                                  }`,
                                                  items: _vm.districtWideRates,
                                                  "item-text": _vm.getDWRText,
                                                  disabled: "",
                                                  dense: "",
                                                  "hide-details": "",
                                                },
                                              })
                                            : _c("v-select", {
                                                attrs: {
                                                  items: _vm.districtWideRates,
                                                  "item-text": _vm.getDWRText,
                                                  "item-value": "id",
                                                  dense: "",
                                                  "hide-details": "",
                                                },
                                                on: {
                                                  change: _vm.handleDWRSelect,
                                                },
                                                model: {
                                                  value:
                                                    _vm.tripRequest
                                                      .estDistrictWideRateId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.tripRequest,
                                                      "estDistrictWideRateId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "tripRequest.estDistrictWideRateId",
                                                },
                                              }),
                                        ],
                                        1
                                      ),
                                      _c("v-spacer"),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "text-left font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.toMoney(_vm.driverCosts))
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c("v-simple-table", {
                        attrs: { dense: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c(
                                    "tr",
                                    [
                                      _c(
                                        "th",
                                        {
                                          staticClass: "text-left",
                                          attrs: { width: "230" },
                                        },
                                        [_vm._v("# Substitutes/Assistants")]
                                      ),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(
                                          "Substitute/Assistant Cost per Person"
                                        ),
                                      ]),
                                      _c("v-spacer"),
                                      _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-left font-weight-bold",
                                          attrs: { width: "200" },
                                        },
                                        [_vm._v("Substitute/Assistant Costs")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("tbody", [
                                  _c(
                                    "tr",
                                    [
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "number",
                                              min: 0,
                                              dense: "",
                                              "hide-details": "",
                                            },
                                            model: {
                                              value:
                                                _vm.tripRequest
                                                  .estNumSubstitutesAssistants,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.tripRequest,
                                                  "estNumSubstitutesAssistants",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tripRequest.estNumSubstitutesAssistants",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.toMoney(
                                              _vm.tripRequestConfig.tripEstimate
                                                .substituteCost
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("v-spacer"),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "text-left font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.toMoney(_vm.subAssistantCosts)
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("v-simple-table", {
                                staticClass: "border-none",
                                attrs: { dense: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left font-weight-bold",
                                                  attrs: { width: "200" },
                                                },
                                                [_vm._v("Standard Trip Fee")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("tbody", [
                                          _c(
                                            "tr",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "td",
                                                {
                                                  staticClass:
                                                    "text-left font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toMoney(
                                                        _vm.standardTripFee
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-0 border-bottom",
                          attrs: { dense: "" },
                        },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("v-simple-table", {
                                staticClass: "border-none",
                                attrs: { dense: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                staticClass:
                                                  "text-left font-weight-bold",
                                                attrs: { width: "200" },
                                              },
                                              [_vm._v("Paid by Student")]
                                            ),
                                          ]),
                                        ]),
                                        _c("tbody", [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              { staticClass: "text-left" },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    type: "number",
                                                    min: 0,
                                                    dense: "",
                                                    "hide-details": "",
                                                    "prepend-inner-icon":
                                                      "mdi-currency-usd",
                                                  },
                                                  on: {
                                                    change: (e) =>
                                                      _vm.handleCostChange(
                                                        e,
                                                        "paidByStudent"
                                                      ),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.tripRequest
                                                        .estPaidByStudent,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.tripRequest,
                                                        "estPaidByStudent",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "tripRequest.estPaidByStudent",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("v-simple-table", {
                                staticClass: "border-none",
                                attrs: { dense: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "th",
                                                {
                                                  staticClass:
                                                    "text-left font-weight-bold",
                                                  attrs: { width: "200" },
                                                },
                                                [_vm._v("Other Costs")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("tbody", [
                                          _c(
                                            "tr",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "td",
                                                { staticClass: "text-left" },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      type: "number",
                                                      min: 0,
                                                      dense: "",
                                                      "hide-details": "",
                                                      "prepend-inner-icon":
                                                        "mdi-currency-usd",
                                                    },
                                                    on: {
                                                      change: (e) =>
                                                        _vm.handleCostChange(
                                                          e,
                                                          "otherCosts"
                                                        ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.tripRequest
                                                          .estOtherCosts,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.tripRequest,
                                                          "estOtherCosts",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "tripRequest.estOtherCosts",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("span", { staticClass: "mr-5" }, [
                      _vm._v("Cost Per Student "),
                    ]),
                    _c("strong", { staticClass: "w-182" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.toMoney(
                              _vm.totalCost / _vm.tripRequest.totalStudents -
                                _vm.tripRequest.estPaidByStudent
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("v-spacer"),
                  _c("v-col", { attrs: { cols: "auto" } }, [
                    _c("span", { staticClass: "mr-5" }, [
                      _vm._v("Total Cost "),
                    ]),
                    _c("strong", { staticClass: "w-182" }, [
                      _vm._v(_vm._s(_vm.toMoney(_vm.totalCost))),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("p", { staticClass: "red--text text--darken-2" }, [
                      _c("strong", { staticClass: "text-h6" }, [_vm._v("*")]),
                      _vm._v(
                        " The trip estimator provides an estimate based on the information entered on the trip request as well as additional information entered into the boxes below. This estimate is for planning purposes only and the actual cost of the trip may vary. Costs such as the driver costs and/or substitute costs may not be billed through this system depending on how your district invoices but will provide approvers with an overall estimate of all costs involved. "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(" Close ")]
              ),
              _c("v-spacer"),
              _vm.hasTripEstimateTemplate
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.print()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-print")]), _vm._v(" Print ")],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.estimate()
                    },
                  },
                },
                [_vm._v(" Save Estimate ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }