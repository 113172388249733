var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.loading ? _c("Spinner") : _vm._e(),
      _vm.approvalLevels.length && !_vm.loading
        ? _c("v-simple-table", [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "text-left" }, [_vm._v("Date")]),
                _c("th", { staticClass: "text-left" }, [_vm._v("Level")]),
                _c("th", { staticClass: "text-left" }, [_vm._v("Name")]),
                _c("th", { staticClass: "text-left" }, [_vm._v("Status")]),
                _c("th", { staticClass: "text-left" }, [_vm._v("Approver")]),
                _vm.isAdmin
                  ? _c("th", { staticClass: "text-left" }, [_vm._v("Action")])
                  : _vm._e(),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.approvalLevels, function (item, index) {
                return _c("tr", { key: index }, [
                  _c("td", [
                    item.status === "approved"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.getApprovedDate(item.approvalLevelId))
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("td", [_vm._v(_vm._s(index + 1))]),
                  _c("td", [_vm._v(_vm._s(item.name))]),
                  _c("td", [_vm._v(_vm._s(_vm.toUcWords(item.status)))]),
                  _c("td", [
                    _c(
                      "span",
                      [
                        item.fundingSourceName
                          ? [_vm._v(_vm._s(item.approverName))]
                          : item.status == "pending"
                          ? [
                              _vm._v(
                                _vm._s(
                                  _vm.getPrimaryApprover(item.approvalLevelId)
                                )
                              ),
                            ]
                          : item.status == "approved"
                          ? [
                              _vm._v(
                                _vm._s(
                                  _vm.getApprovedName(item.approvalLevelId)
                                )
                              ),
                            ]
                          : [
                              _vm._v(
                                _vm._s(
                                  _vm.getApproverInAudit(item.approvalLevelId)
                                )
                              ),
                            ],
                      ],
                      2
                    ),
                  ]),
                  _vm.isAdmin
                    ? _c(
                        "td",
                        [
                          item.status == "approved"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleReinitiateApproval(
                                        item.approvalLevelId
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Reinitiate Approval ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
      !_vm.approvalLevels.length && !_vm.loading
        ? _c("v-alert", { attrs: { type: "info" } }, [
            _vm._v(" This trip has no approvers and is auto approved. "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }