<template>
  <v-dialog v-model="dialog" max-width="1200px" no-click-animation>
    <v-card>
      <v-card-title>Trip Estimator</v-card-title>

      <v-card-text class="pt-5">
        <v-row>
          <v-col cols="12" md="12">
            <v-card outlined>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" md="4">
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <div class="text-subtitle-2 font-weight-bold">Trip #</div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="text-subtitle-2 font-weight-normal">
                          {{ tripRequest.id }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <div class="text-subtitle-2 font-weight-bold"># Vehicles Needed</div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="text-subtitle-2 font-weight-normal">
                          {{ tripRequest.numVehicles }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <div class="text-subtitle-2 font-weight-bold">Leave Date/Time</div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="text-subtitle-2 font-weight-normal">{{ leaveText }}</div>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <div class="text-subtitle-2 font-weight-bold">Return Date/Time</div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="text-subtitle-2 font-weight-normal">{{ returnText }}</div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <div class="text-subtitle-2 font-weight-bold">Approx Round-Trip Mileage</div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="text-subtitle-2 font-weight-normal">
                          {{ tripRequest.distance }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <div class="text-subtitle-2 font-weight-bold">Destination</div>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div class="text-subtitle-2 font-weight-normal">
                          {{
                            tripRequest.destinationId && destinationsById[tripRequest.destinationId]
                              ? destinationsById[tripRequest.destinationId].name
                              : ''
                          }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="12">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" width="230">Total Miles</th>
                    <th class="text-left">Cost Per Mile</th>
                    <th class="text-left">Additional Cost Per Mile</th>
                    <v-spacer></v-spacer>
                    <th class="text-left font-weight-bold" width="200">Mileage Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ tripRequest.distance }}</td>
                    <td>${{ costPerMile }}</td>
                    <td>${{ tripTypesById[tripRequest.tripTypeId].additionalCost }}</td>
                    <v-spacer></v-spacer>
                    <td class="text-left font-weight-bold">{{ toMoney(mileageCost) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" width="230">Total Miles</th>
                    <th class="text-left">Fuel Cost per Gallon</th>
                    <v-spacer></v-spacer>
                    <th class="text-left font-weight-bold" width="200">Fuel Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ tripRequest.distance }}</td>
                    <td>{{ toMoney(currentFuel.pricePerGallon) }}</td>
                    <v-spacer></v-spacer>
                    <td class="text-left font-weight-bold">{{ toMoney(fuelCost) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" width="230"># of Students</th>
                    <th class="text-left">Venue Cost per Student</th>
                    <v-spacer></v-spacer>
                    <th class="text-left font-weight-bold" width="200">Venue Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ tripRequest.totalStudents }}</td>
                    <td>
                      <v-text-field
                        v-model="tripRequest.estVenueCostPerStudent"
                        type="number"
                        :min="0"
                        dense
                        hide-details
                        prepend-inner-icon="mdi-currency-usd"
                        @change="(e) => handleCostChange(e, 'venueCostPerStudent')"
                      ></v-text-field>
                    </td>
                    <v-spacer></v-spacer>
                    <td class="text-left font-weight-bold">{{ toMoney(venueCost) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" width="230">Driver Hours per Driver</th>
                    <th class="text-left">District-Wide Driver Rate</th>
                    <v-spacer></v-spacer>
                    <th class="text-left font-weight-bold" width="200">Driver Costs</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-text-field
                        v-model="tripRequest.estDriverHours"
                        type="number"
                        :min="0"
                        dense
                        hide-details
                      ></v-text-field>
                    </td>
                    <td>
                      <v-select
                        v-if="hasOverRideRate"
                        :label="`$${tripEventsById[tripRequest.tripEventIds[0]].driverRate}`"
                        :items="districtWideRates"
                        :item-text="getDWRText"
                        disabled
                        dense
                        hide-details
                      ></v-select>

                      <v-select
                        v-else
                        v-model="tripRequest.estDistrictWideRateId"
                        :items="districtWideRates"
                        :item-text="getDWRText"
                        item-value="id"
                        dense
                        hide-details
                        @change="handleDWRSelect"
                      ></v-select>
                    </td>

                    <v-spacer></v-spacer>
                    <td class="text-left font-weight-bold">{{ toMoney(driverCosts) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left" width="230"># Substitutes/Assistants</th>
                    <th class="text-left">Substitute/Assistant Cost per Person</th>
                    <v-spacer></v-spacer>
                    <th class="text-left font-weight-bold" width="200">Substitute/Assistant Costs</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-text-field
                        v-model="tripRequest.estNumSubstitutesAssistants"
                        type="number"
                        :min="0"
                        dense
                        hide-details
                      ></v-text-field>
                    </td>
                    <td>{{ toMoney(tripRequestConfig.tripEstimate.substituteCost) }}</td>
                    <v-spacer></v-spacer>
                    <td class="text-left font-weight-bold">{{ toMoney(subAssistantCosts) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row dense>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-simple-table dense class="border-none">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <v-spacer></v-spacer>
                        <th class="text-left font-weight-bold" width="200">Standard Trip Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <v-spacer></v-spacer>
                        <td class="text-left font-weight-bold">{{ toMoney(standardTripFee) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row dense class="mt-0 border-bottom">
              <v-col cols="auto">
                <v-simple-table dense class="border-none">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left font-weight-bold" width="200">Paid by Student</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-left">
                          <v-text-field
                            v-model="tripRequest.estPaidByStudent"
                            type="number"
                            :min="0"
                            dense
                            hide-details
                            prepend-inner-icon="mdi-currency-usd"
                            @change="(e) => handleCostChange(e, 'paidByStudent')"
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-simple-table dense class="border-none">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <v-spacer></v-spacer>
                        <th class="text-left font-weight-bold" width="200">Other Costs</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <v-spacer></v-spacer>
                        <td class="text-left">
                          <v-text-field
                            v-model="tripRequest.estOtherCosts"
                            type="number"
                            :min="0"
                            dense
                            hide-details
                            prepend-inner-icon="mdi-currency-usd"
                            @change="(e) => handleCostChange(e, 'otherCosts')"
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="auto">
            <span class="mr-5">Cost Per Student </span>
            <strong class="w-182">
              {{ toMoney(totalCost / tripRequest.totalStudents - tripRequest.estPaidByStudent) }}
            </strong>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <span class="mr-5">Total Cost </span><strong class="w-182">{{ toMoney(totalCost) }}</strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="red--text text--darken-2">
              <strong class="text-h6">*</strong>
              The trip estimator provides an estimate based on the information entered on the trip request as well as
              additional information entered into the boxes below. This estimate is for planning purposes only and the
              actual cost of the trip may vary. Costs such as the driver costs and/or substitute costs may not be billed
              through this system depending on how your district invoices but will provide approvers with an overall
              estimate of all costs involved.
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn class="mx-2" color="primary" @click="download()"> <v-icon>mdi-download</v-icon> Download </v-btn> -->
        <v-btn v-if="hasTripEstimateTemplate" color="primary" @click="print()"><v-icon>mdi-print</v-icon> Print </v-btn>
        <v-btn color="primary" @click="estimate()"> Save Estimate </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SAVE_TRIP_REQUEST, DOWNLOAD_TRIP_ESTIMATE } from '@/store/modules/TripRequest/actions';
import { format, getTime } from 'date-fns';
import { randomString, toMoney, todayString } from '@/util';

export default {
  name: 'TripEstimator',
  inject: ['eventHub'],
  mixins: [],
  components: {},
  props: {
    tripRequest: Object,
  },
  data() {
    return {
      toMoney,
      venueCostPerStudent: 0.0,
      driverHours: 0.0,
      numSubsAssistants: 0.0,
      otherCosts: 0.0,
      paidByStudent: 0.0,
      selectedDWRId: 0,
      dialog: false,
      days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('app', ['hasTripEstimateTemplate']),
    ...mapGetters('tripType', ['tripTypesById']),
    ...mapGetters('tripEvent', ['tripEventsById']),
    ...mapGetters('destination', ['destinationsById']),
    ...mapGetters('config', ['tripRequestConfig', 'districtWideRates', 'fuelPrices']),
    hasOverRideRate() {
      return (
        !!this.tripTypesById[this.tripRequest.tripTypeId].allowEventRates &&
        this.tripEventsById[this.tripRequest.tripEventIds[0]].overrideDriver == 1
      );
    },
    currentFuel() {
      const current = this.fuelPrices.find((e) => new Date(this.tripRequest.leaveDate) >= new Date(e.begin));
      return current || { pricePerGallon: 0, milesPerGallon: 0 };
    },
    estimateHours() {
      const leaveAt = `${this.tripRequest.leaveDate} ${this.tripRequest.leaveTime}`;
      const returnAt = `${this.tripRequest.returnDate} ${this.tripRequest.returnTime}`;
      const leaveDate = new Date(leaveAt);
      const returnDate = new Date(returnAt);
      return Number(((returnDate.getTime() - leaveDate.getTime()) / 3600000).toFixed(2));
    },
    costPerMile() {
      if (!this.tripRequest.tripTypeId) return 0;
      if (
        this.tripRequest.tripEventIds.length == 1 &&
        this.tripTypesById[this.tripRequest.tripTypeId].allowEventRates &&
        this.tripEventsById[this.tripRequest.tripEventIds[0]].mileageRate > 0
      ) {
        return this.tripEventsById[this.tripRequest.tripEventIds[0]].mileageRate;
      }
      return this.tripTypesById[this.tripRequest.tripTypeId].costPerMile;
    },
    mileageCost() {
      if (!this.tripRequest.tripTypeId) return 0;

      // defaults to 1 if the trip doesn't need any vehicles
      let numOfVehicles = this.tripRequest.needDistrictVehicles ? this.tripRequest.numVehicles : 1;

      const mileage =
        (parseFloat(this.costPerMile) + parseFloat(this.tripTypesById[this.tripRequest.tripTypeId].additionalCost)) *
        parseFloat(this.tripRequest.distance) *
        numOfVehicles;
      return isNaN(mileage) ? 0 : mileage;
    },
    fuelCost() {
      if (!this.tripRequest.distance) return 0;
      const cost =
        parseFloat(this.tripRequest.distance / this.currentFuel.milesPerGallon) *
        parseFloat(this.currentFuel.pricePerGallon) *
        this.tripRequest.numVehicles;
      return isNaN(cost) ? 0 : cost;
    },
    venueCost() {
      return this.tripRequest.totalStudents * this.tripRequest.estVenueCostPerStudent;
    },
    estimatedDriverHoursNotProvided() {
      return (
        (new Date(`${this.tripRequest.returnDate} ${this.tripRequest.returnTime}`) -
          new Date(`${this.tripRequest.leaveDate} ${this.tripRequest.leaveTime}`)) /
        (1000 * 60 * 60)
      ).toFixed(2);
    },
    driverCosts() {
      const tripEvent = this.tripEventsById[this.tripRequest.tripEventIds[0]];
      const districtWideRate = this.districtWideRates.find(
        (rate) => rate.id === this.tripRequest.estDistrictWideRateId
      );

      if (tripEvent && this.hasOverRideRate) {
        return this.tripRequest.estDriverHours * tripEvent.driverRate;
      } else {
        const rate = districtWideRate ? districtWideRate.rate : 0;
        return this.tripRequest.estDriverHours * rate;
      }
    },
    subAssistantCosts() {
      return this.tripRequest.estNumSubstitutesAssistants * this.tripRequestConfig.tripEstimate.substituteCost;
    },
    standardTripFee() {
      if (!this.tripRequest.tripTypeId) return 0;
      return this.tripTypesById[this.tripRequest.tripTypeId].standardFee;
    },
    totalCost() {
      return (
        parseFloat(this.mileageCost) +
        parseFloat(this.fuelCost || 0) +
        parseFloat(this.venueCost) +
        parseFloat(this.driverCosts) +
        parseFloat(this.subAssistantCosts) +
        parseFloat(this.standardTripFee) +
        parseFloat(this.tripRequest.estOtherCosts)
      );
    },
    leaveText() {
      if (!this.tripRequest.leaveDate) return '';
      const d = new Date(this.tripRequest.leaveDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      const leaveDate = format(dt, 'LLL dd');
      const s = this.tripRequest.leaveTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      const leaveTime = `${hour}:${minute} ${ampm} (${this.days[dt.getDay()]})`;
      return leaveDate + ' @ ' + leaveTime;
    },
    returnText() {
      if (!this.tripRequest.returnDate) return '';
      const d = new Date(this.tripRequest.returnDate);
      const dt = new Date(d.valueOf() + d.getTimezoneOffset() * 60 * 1000);
      const returnDate = format(dt, 'LLL dd');
      const s = this.tripRequest.returnTime.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      const returnTime = `${hour}:${minute} ${ampm} (${this.days[dt.getDay()]})`;
      return returnDate + ' @ ' + returnTime;
    },
  },
  mounted() {
    this.tripRequest.estDistrictWideRateId =
      this.tripRequest.estDistrictWideRateId || this.tripRequestConfig.tripEstimate.defaultRate || 0;
  },
  methods: {
    ...mapActions('tripRequest', [SAVE_TRIP_REQUEST, DOWNLOAD_TRIP_ESTIMATE]),
    async estimate(silently = false) {
      try {
        this.saving = true;
        const r = await this.saveTripRequest({
          id: this.tripRequest.id,
          estVenueCostPerStudent: this.tripRequest.estVenueCostPerStudent,
          estDriverHours: this.tripRequest.estDriverHours,
          estDistrictWideRateId: this.tripRequest.estDistrictWideRateId,
          estNumSubstitutesAssistants: this.tripRequest.estNumSubstitutesAssistants,
          estOtherCosts: this.tripRequest.estOtherCosts,
          estPaidByStudent: this.tripRequestConfig.estPaidByStudent,
          estTotalCost: this.totalCost,
          raw: true,
        });
        if (r && r.id && !silently) {
          this.$myalert.success('Trip Estimate saved');
          // await this.refreshTripRequest(r.id);
        }
        if (silently) return { hasError: false, message: '' };
      } catch (error) {
        if (silently) return { hasError: true, message: error.message };
        this.$myalert.error(error.message);
      }
    },
    async print() {
      try {
        await this.downloadTripEstimate({
          tripRequestId: this.tripRequest.id,
          uuid: randomString(16),
          reportId: 0,
        });
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    handleCostChange(e, field) {
      this[field] = toMoney(e, 2, true);
      this.$nextTick();
    },
    handleDWRSelect(e) {
      this.tripRequest.estDistrictWideRateId = e;
    },
    getDWRText(dwr) {
      return `${dwr.name} (${toMoney(dwr.rate)})`;
    },
  },
  watch: {
    tripRequest(value) {
      // if (value.time) this.tripRequest.estDriverHours = Number((value.time / 3600000).toFixed(2));
      if (!value.estDriverHours) this.tripRequest.estDriverHours = this.estimatedDriverHoursNotProvided;
    },
  },
};

// const leave = '2023-03-29 1:00';
// const rDate = '2023-03-29 13:00';

// const leaveDate = new Date(leave);
// const returnDate = new Date(rDate);
// const differenceInMilliseconds = returnDate.getTime() - leaveDate.getTime();
// const differenceInHours = differenceInMilliseconds / 3600000;
</script>

<style scoped>
::v-deep .v-text-field {
  width: 100px;
}
::v-deep .v-select {
  width: 250px !important;
}
::v-deep .v-data-table__wrapper table {
  border: #e0e0e0 1px solid;
  border-radius: 4px;
  padding-bottom: 4px;
  margin-bottom: 4px;
}
::v-deep .border-none table {
  border: none !important;
  width: 200px !important;
}
.border-bottom {
  border-bottom: #e0e0e0 1px solid;
}
.w-182 {
  width: 182px;
  display: inline-block;
}
</style>
